import React from 'react';

import { useEffect } from 'react';
import { useAppConfig } from '@rugby-au/app-config';
import { checkToken, getItem, useFetch } from '@rugby-au/business';
import { ActivityIndicator } from 'react-native';
import { useRouter } from 'next/router';

export default function App({}: {}) {
  const { setNavigation, setIsLogged } = useAppConfig();
  const { fetchRequest } = useFetch({});

  const params = useRouter();
  useEffect(() => {
    getItem('client')
      .then((client: any) => {
        fetchRequest(checkToken, client)
          .then((user: any) => {
            if (user && user.userId && !user.error) {
              let authCode = user?.authCode;
              if (!authCode) {
                authCode = client?.authCode;
              }
              let redirectUrl = user?.redirectUrl;
              if (!redirectUrl) {
                redirectUrl = 'https://www.rugby.com.au';
              }
              if (client && client.redirectPath) {
                redirectUrl = redirectUrl?.substring(redirectUrl.length - 1) === '/' ? redirectUrl?.substring(0, redirectUrl.length - 1) : redirectUrl;
                let redirectPath = client.redirectPath?.startsWith('/') ? client.redirectPath : `/${client.redirectPath}`;
                redirectUrl = `${redirectUrl}${redirectPath}`;
              }
              if (authCode) {
                if (client?.noPkce) {
                  authCode += `^|^.u6YT^${user?.token}`;
                }
                redirectUrl += `${redirectUrl.includes('?') ? '&' : '?'}authCode=${authCode}&clientId=${client?.username}`;
              } else {
                console.error('file: index.tsx:29 ~ No authCode ', JSON.stringify(user), JSON.stringify(client));
              }
              window.location.href = redirectUrl;
            } else {
              setIsLogged(false);
              setNavigation && setNavigation(`/login${params.asPath}`);
            }
          })
          .catch(e => {
            console.error('file: index.tsx:29 ~ getItem ~ e:', e);
            setIsLogged(false);
          });
      })
      .catch(e => {
        console.error('file: index.tsx:35 ~ getItem ~ e:', e);
        setIsLogged(false);
      });
  }, [setNavigation, params.asPath]);

  return <ActivityIndicator size={'large'} />;
}
